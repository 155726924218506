import { getPricingPlansPublicAPI } from '@wix/pricing-plans-tpa-api';
import type { IWixAPI } from '@wix/yoshi-flow-editor';
import {
  BLOG_APP_ID,
  BLOG_POST_PAGE_SECTION_ID,
} from '@wix/communities-universal/dist/src/constants/appsConfig';
import { createAction } from '@wix/communities-blog-client-common';
import { REFERRAL_BLOG_POST_PAYWALL } from '../../common/constants/paid-plans';
import type { AppState, NormalizedPost } from '../../common/types';
import { type PostPageThunkAction } from '../types';

const fetchPricingPlansRequest = createAction('pricingPlans/FETCH_REQUEST');

export const fetchPricingPlansUrlSuccess = createAction(
  'pricingPlans/FETCH_SUCCESS',
  (payload: string | undefined) => payload,
);

const fetchPricingPlansUrlFailure = createAction('pricingPlans/FETCH_FAILURE');

const getOptions = ({
  translations,
  post,
}: {
  translations: AppState['translations'];
  post: NormalizedPost;
}) => {
  const content = {
    title: translations['subscription.thanks-page.title'],
    message: translations['subscription.thanks-page.text'],
    cta: translations['subscription.thanks-page.continue-button'],
  };

  return {
    planIds: post.paidPlansGroupIds ?? [],
    checkout: {
      thankYouPage: {
        content,
        navigation: {
          type: 'section' as const,
          options: {
            sectionId: BLOG_POST_PAGE_SECTION_ID,
            appDefinitionId: BLOG_APP_ID,
            state: post.slug,
          },
        },
      },
    },
    biOptions: {
      referralInfo: REFERRAL_BLOG_POST_PAYWALL,
      referralId: post.id,
    },
  };
};

export default function fetchPricingPlansUrl(
  post: NormalizedPost,
): PostPageThunkAction {
  return async (dispatch, getState, { platformApi }) => {
    dispatch(fetchPricingPlansRequest());
    try {
      const { translations } = getState();

      const options = getOptions({
        translations,
        post,
      });

      const pricingPlansApi = await getPricingPlansPublicAPI(
        platformApi as unknown as IWixAPI,
      );
      const url = await pricingPlansApi.getPricingPageUrl(options);
      dispatch(fetchPricingPlansUrlSuccess(url));
    } catch (error) {
      dispatch(fetchPricingPlansUrlFailure(error));
    }
  };
}
