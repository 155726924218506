import { type PlatformApi } from '@app/external/common/controller/platform-api';

/**
 * Wraps IWixWindow.warmupData API by encoding values as base64 before saving them
 *
 * This allows to safely store text containing HTML, otherwise some
 * the untreated HTML could break JSON.parse on the warmupData object.
 * */
export class WarmupDataHandler<T> {
  private readonly isSSR: boolean;
  private readonly isWarmupDataExperimentEnabled: boolean;
  private readonly loaderFn: () => Promise<T>;
  private readonly platformApi: PlatformApi;
  private readonly warmupDataKey: string;

  constructor(config: {
    isSSR: boolean;
    isWarmupDataExperimentEnabled: boolean;
    loaderFn: () => Promise<T>;
    platformApi: PlatformApi;
    warmupDataKey: string;
  }) {
    this.isSSR = config.isSSR;
    this.isWarmupDataExperimentEnabled = config.isWarmupDataExperimentEnabled;
    this.loaderFn = config.loaderFn;
    this.platformApi = config.platformApi;
    this.warmupDataKey = config.warmupDataKey;
  }

  async load(): Promise<T> {
    if (this.isSSR && this.isWarmupDataExperimentEnabled) {
      const data = await this.loaderFn();

      this.platformApi.window.warmupData.set(
        this.warmupDataKey,
        this.encoder(data),
      );

      return data;
    }

    const decodedValue = this.decoder(
      this.platformApi.window.warmupData.get(this.warmupDataKey),
    );

    return decodedValue ?? this.loaderFn();
  }

  private encoder(data: T) {
    return btoa(JSON.stringify(data));
  }

  private decoder(data: string): T | undefined {
    try {
      return JSON.parse(atob(data));
    } catch {
      return undefined;
    }
  }
}
