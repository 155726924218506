import { getPricingPlansPublicAPI } from '@wix/pricing-plans-tpa-api';
import type {
  IWixAPI,
  PlatformControllerFlowAPI,
} from '@wix/yoshi-flow-editor';
import { likePost, unlikePost } from '@wix/ambassador-blog-v3-post/http';
import { raise } from '@wix/communities-blog-client-common';
import { MEMBERS_PROFILE_WIDGET_ID } from '../../common/members';
import { blogAppDefId } from '../common/constants/apps';
import { SANTA_MEMBERS_APP_ID } from '../common/constants/members-area';
import { TPA_PAGE_ID_BLOG } from '../common/constants/tpa-pages';
import { type PlatformApi } from '../common/controller/platform-api';
import { getBlogFeedSectionUrl } from '../common/services/get-section-url';
import { type NormalizedPost } from '../common/types';
import { getPricingPlansOptions } from './helpers/pricing-plans-options';

export class TranslatedPostRedirectError extends Error {}
export class TranslatedPostNotFoundError extends Error {
  constructor() {
    super('Post language does not match current language', {
      cause: 404,
    });
  }
}

export class BlocksWidgetFacade {
  constructor(
    private readonly flowAPI: PlatformControllerFlowAPI,
    private readonly platformApi: PlatformApi,
  ) {}

  /**
   * @example
   * ```ts
   * await facade.getUrlToFeedPage(); // -> https://user.wixsite.com/site-1/blog
   * ```
   */
  getUrlToFeedPage() {
    return getBlogFeedSectionUrl(this.platformApi);
  }

  async likePost(postId: string) {
    return this.flowAPI.httpClient.request(likePost({ postId }));
  }

  async dislikePost(postId: string) {
    return this.flowAPI.httpClient.request(unlikePost({ postId }));
  }

  async getUrlToPricingPage(post: NormalizedPost) {
    const api = await getPricingPlansPublicAPI(
      this.platformApi as unknown as IWixAPI,
    );
    const options = getPricingPlansOptions({
      pricingPlanIds: post.pricingPlanIds,
      id: post.id,
      slug: post.slug,
    });

    return api.getPricingPageUrl(options);
  }

  async getMembersProfileUrl(post: NormalizedPost) {
    const memberId =
      post.owner?.siteMemberId || raise('"Post.owner.siteMemberId" not found');
    const memberSlug = post.owner?.slug || raise('"Post.owner.slug" not found');

    const membersAreaAPI = await this.platformApi.site.getPublicAPI(
      SANTA_MEMBERS_APP_ID,
    );
    const profileURL = await membersAreaAPI.getSectionUrl({
      memberId,
      memberSlug,
      widgetId: MEMBERS_PROFILE_WIDGET_ID,
    });

    return profileURL;
  }

  /**
   * @throws {RedirectError} - if post language does not match current language after redirecting to the posts correct language
   * @throws {TranslatedPostNotFoundError} - if post language does not match current language and no translated post is found
   */
  assertPostIsLoadedInCorrectLanguage(post: NormalizedPost): void | never {
    const currentLanguage =
      this.platformApi.window.multilingual.currentLanguage ||
      (this.platformApi.site.language ?? 'en');

    if (post.language === currentLanguage) {
      return;
    }

    const translation = post.translations?.find(
      (t) => t.language === currentLanguage,
    );

    if (translation?.url) {
      const { base, path } = translation.url;
      if (base && path) {
        const { searchParams } = new URL(this.platformApi.location.url);
        const url = `${base}${path}`;

        this.platformApi.location.to?.(
          searchParams.size > 0 ? `${url}?${searchParams}` : url,
        );

        throw new TranslatedPostRedirectError();
      }
    } else {
      throw new TranslatedPostNotFoundError();
    }
  }
}
